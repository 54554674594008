/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import emailjs, { init } from 'emailjs-com';
import { Grid, Segment, Header, Button, Form, Image } from 'semantic-ui-react';

import { Media, MediaContextProvider } from '../media';
import { SEO } from '../components/seo';
import Section from '../components/section';
import Nicol from '../images/nicole.svg';
import Indelo from '../images/indelo.png';
import Belombre from '../images/belombre.png';
import Sendit from '../images/sendit.png';
import Techup from '../images/techup.png';
import Mindjoy from '../images/mindjoy.png';
import Jumo from '../images/jumo.png';
import AG from '../images/ag.png';
import CV from '../images/CV.pdf';
import Offerzen from '../images/offerzen.png';
import Praekelt from '../images/praekelt.png';
import Escrow from '../images/escrow.png';
import Queuemetrics from '../images/queuemetrics.png';
import Layout from '../components/layout';
import Hero from '../components/hero';
import { ProjectCard } from '../components/project-card';
import { PageFooter } from '../components/page-footer';
import { TechnologyStack } from '../components/technology-stack';

const RootIndex = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const sendEmail = async () => {
    if (!name || !message || !email) {
      setError(true);
      return;
    }
    setSubmitted(true);

    emailjs
      .sendForm('service_9h02fkb', 'template_wcnf7gs', {
        from_name: name,
        from_email: email,
        message,
      })
      .then(
        (result) => {
          console.log(result.text);
        },
        (e) => {
          console.log(e.text);
        },
      );
  };

  const projects = {
    techup: {
      header: 'TechUp School',
      subheader:
        'An online school that trains individuals to become employable software developers',
      body:
        'In 2021 I co-founded TechUp School and since inception have helped mentor over 20 individuals in software development. I maintain the website with my co-founder which is built in React.js',
      image: Techup,
      websiteUrl: 'https://www.techupschool.com/',
      designUrl: '',
    },
    mindjoy: {
      header: 'Mindjoy',
      subheader: 'Teaching kids to code',
      body:
        "In 2021 I helped re-design and build Mindjoy's current marketing pages (React.js) and did development work on their core platform (Vue.js)",
      image: Mindjoy,
      websiteUrl: 'https://mindjoy.com/',
      designUrl: '',
    },
    indelo: {
      header: 'Indelo',
      subheader: 'Design and development agency',
      body:
        'During 2020 I co-founded a web development and design startup and built the Indelo website. We have worked with several clients over the last 2 years.',
      image: Indelo,
      websiteUrl: 'https://indelo.co.za/',
      designUrl:
        'https://www.figma.com/file/ntSZ4TH3fH6GyKYCJ7nGIq/indelo-website',
    },
    belombre: {
      header: 'Bel Ombre Cottages',
      subheader: 'BnB accommodation',
      body: 'I built a BnB website using Gatsby.js, hosted on Netlify',
      image: Belombre,
      websiteUrl: 'https://www.belombrecottages.co.za/',
      designUrl:
        'https://www.figma.com/file/OOhTFJCVQxyyPJNEeW9IPJ/Bel-Ombre-Cottages',
    },
    easyEscrow: {
      header: 'Easy Escrow',
      subheader: 'Online escrow service',
      body:
        'EasyEscrow is an online escrow service built to help South African consumers eliminate fraud. I built the marketing pages and worked on parts of the dashboard backend (Node) and frontend (React)',
      image: Escrow,
      websiteUrl: 'http://easyescrow-website-staging.herokuapp.com/',
      designUrl: '',
    },
    queueMetrics: {
      header: 'Queuemetrics (Now QueueKit)',
      subheader: 'Queue management and analytics',
      body:
        'I built the marketing pages of a queue management and analytics project with React.js',
      image: Queuemetrics,
      websiteUrl: 'https://queuemetrics.io/',
      designUrl: '',
    },
    sendit: {
      header: 'Sendit',
      subheader: 'Video eventing',
      body:
        'As part of Indelo, I designed and built the Sendit marketing pages using Figma, Tailwind and Vue.js',
      image: Sendit,
      websiteUrl: 'https://www.sendit.media/',
      designUrl:
        'https://www.figma.com/file/Nl9rvWscHi5pgBKLtK1vDj/SendIt-UI?node-id=156%3A451',
    },
  };

  const employers = [Offerzen, AG, Jumo, Praekelt];

  return (
    <>
      <MediaContextProvider>
        <Hero />
        <Layout>
          <div style={{ position: 'relative' }}>
            <Section header="A little bit about me" id="about">
              <p
                style={{
                  fontFamily: 'Avenir, Tahoma, Arial, Helvetica, sans-serif',
                  fontSize: 18,
                  color: '#383838',
                }}
              >
                I am a software developer with 5 years of experience in full
                stack technologies including React.js, Node.js, Ruby on Rails
                and Python. I enjoy solving complex problems and in the last two
                years I have developed a passion for startups and design.
                <br />
                <br />
                Since September 2021 I have been building{' '}
                <a
                  style={{ color: '#8a46ff' }}
                  href="https://www.techupschool.com/"
                >
                  TechUp School
                </a>{' '}
                and working part-time doing software development and product
                design. I have also been upskilling myself in product management
                in 2022 as the topic really excites me.
                <br />
                <br />
                I am incredibly passionate about mentoring and I value team and
                company culture very highly. I prioritise working with smart,
                friendly individuals who push me to grow on a technical and
                personal level, but also make me excited about going to work
                every day.
                <br />
                <br />I am based in Cape Town and hold an EU dual-citizenship.
              </p>
            </Section>
            <Section
              id="previousEmployers"
              header="Previous Employers"
              subheader=""
            >
              <Media greaterThan="mobile">
                <div
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}
                >
                  {employers.map((employer) => (
                    <Image style={{ width: '15%' }} src={employer} />
                  ))}
                </div>
              </Media>

              <Media at="mobile">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {employers.map((employer) => (
                    <Image
                      style={{ width: '50%', marginBottom: 20 }}
                      src={employer}
                    />
                  ))}
                </div>
              </Media>
            </Section>
            <Section
              id="portfolio"
              header="Portfolio"
              subheader="Projects and products I have designed and built"
            >
              <Media at="mobile">
                <ProjectCard
                  header={projects.techup.header}
                  subheader={projects.techup.subheader}
                  body={projects.techup.body}
                  image={projects.techup.image}
                  websiteUrl={projects.techup.websiteUrl}
                  designUrl={projects.techup.designUrl}
                />
                <ProjectCard
                  header={projects.indelo.header}
                  subheader={projects.indelo.subheader}
                  body={projects.indelo.body}
                  image={projects.indelo.image}
                  websiteUrl={projects.indelo.websiteUrl}
                  designUrl={projects.indelo.designUrl}
                />
                <ProjectCard
                  header={projects.mindjoy.header}
                  subheader={projects.mindjoy.subheader}
                  body={projects.mindjoy.body}
                  image={projects.mindjoy.image}
                  websiteUrl={projects.mindjoy.websiteUrl}
                  designUrl={projects.mindjoy.designUrl}
                />
                <ProjectCard
                  header={projects.easyEscrow.header}
                  subheader={projects.easyEscrow.subheader}
                  body={projects.easyEscrow.body}
                  image={projects.easyEscrow.image}
                  websiteUrl={projects.easyEscrow.websiteUrl}
                  designUrl={projects.easyEscrow.designUrl}
                />
                <ProjectCard
                  header={projects.belombre.header}
                  subheader={projects.belombre.subheader}
                  body={projects.belombre.body}
                  image={projects.belombre.image}
                  websiteUrl={projects.belombre.websiteUrl}
                  designUrl={projects.belombre.designUrl}
                />
                <ProjectCard
                  header={projects.sendit.header}
                  subheader={projects.sendit.subheader}
                  body={projects.sendit.body}
                  image={projects.sendit.image}
                  websiteUrl={projects.sendit.websiteUrl}
                  designUrl={projects.sendit.designUrl}
                />
                <ProjectCard
                  header={projects.queueMetrics.header}
                  subheader={projects.queueMetrics.subheader}
                  body={projects.queueMetrics.body}
                  image={projects.queueMetrics.image}
                  websiteUrl={projects.queueMetrics.websiteUrl}
                  designUrl={projects.queueMetrics.designUrl}
                />
              </Media>
              <Media greaterThan="mobile">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginBottom: 30,
                  }}
                >
                  <ProjectCard
                    header={projects.indelo.header}
                    subheader={projects.indelo.subheader}
                    body={projects.indelo.body}
                    image={projects.indelo.image}
                    websiteUrl={projects.indelo.websiteUrl}
                    designUrl={projects.indelo.designUrl}
                  />
                  <ProjectCard
                    header={projects.techup.header}
                    subheader={projects.techup.subheader}
                    body={projects.techup.body}
                    image={projects.techup.image}
                    websiteUrl={projects.techup.websiteUrl}
                    designUrl={projects.techup.designUrl}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginBottom: 30,
                  }}
                >
                  <ProjectCard
                    header={projects.easyEscrow.header}
                    subheader={projects.easyEscrow.subheader}
                    body={projects.easyEscrow.body}
                    image={projects.easyEscrow.image}
                    websiteUrl={projects.easyEscrow.websiteUrl}
                    designUrl={projects.easyEscrow.designUrl}
                  />
                  <ProjectCard
                    header={projects.mindjoy.header}
                    subheader={projects.mindjoy.subheader}
                    body={projects.mindjoy.body}
                    image={projects.mindjoy.image}
                    websiteUrl={projects.mindjoy.websiteUrl}
                    designUrl={projects.mindjoy.designUrl}
                  />
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <ProjectCard
                    header={projects.belombre.header}
                    subheader={projects.belombre.subheader}
                    body={projects.belombre.body}
                    image={projects.belombre.image}
                    websiteUrl={projects.belombre.websiteUrl}
                    designUrl={projects.belombre.designUrl}
                  />
                  <ProjectCard
                    header={projects.queueMetrics.header}
                    subheader={projects.queueMetrics.subheader}
                    body={projects.queueMetrics.body}
                    image={projects.queueMetrics.image}
                    websiteUrl={projects.queueMetrics.websiteUrl}
                    designUrl={projects.queueMetrics.designUrl}
                  />
                  <ProjectCard
                    header={projects.sendit.header}
                    subheader={projects.sendit.subheader}
                    body={projects.sendit.body}
                    image={projects.sendit.image}
                    websiteUrl={projects.sendit.websiteUrl}
                    designUrl={projects.sendit.designUrl}
                  />
                </div>
              </Media>
            </Section>
            <Section
              header="Technologies"
              subheader="Technologies I use to build cool things"
              id="technologies"
            >
              <TechnologyStack />
            </Section>

            <Section
              header="Contact"
              id="contact"
              // subheader="Have a question? Send me a message and I'll get back to you shortly :)"
            >
              <div style={{ textAlign: 'center', fontSize: 24 }}>
                nvojacek@gmail.com
              </div>
              <div style={{ textAlign: 'center', marginBottom: 20 }}>
                <Button
                  circular
                  icon="twitter"
                  color="twitter"
                  as={(properties: any) => (
                    <a
                      {...properties}
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/NicolNvojacek"
                    />
                  )}
                />
                <Button
                  circular
                  icon="linkedin"
                  color="linkedin"
                  as={(properties: any) => (
                    <a
                      {...properties}
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/in/nicol-vojacek-444271106/"
                    />
                  )}
                />
                <Button
                  circular
                  icon="github"
                  as={(properties: any) => (
                    <a
                      {...properties}
                      target="_blank"
                      rel="noreferrer"
                      href="https://github.com/ni3ol"
                    />
                  )}
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <a rel="noreferrer" target="_blank" href={CV}>
                  Download CV
                </a>
              </div>
              {/* <Media at="mobile">
                <Segment
                  style={{
                    width: '90%',
                    padding: 24,
                    boxShadow: '0 1px 10px 2px rgb(123 136 168 / 15%)',
                    border: 'none',
                    margin: 'auto',
                  }}
                >
                  <Form onSubmit={sendEmail}>
                    <Form.Field
                      error={error && !name}
                      onChange={(e) => setName(e.target.value)}
                    >
                      <label>Name</label>
                      <input placeholder="Name" />
                    </Form.Field>
                    <Form.Field
                      error={error && !email}
                      onChange={(e) => setEmail(e.target.value)}
                    >
                      <label>Email</label>
                      <input placeholder="Email" />
                    </Form.Field>
                    <Form.TextArea
                      error={error && !message}
                      onChange={(e) => setMessage(e.target.value)}
                      label="Message"
                      placeholder="Type your message here"
                    />
                    <Button
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                      primary
                    >
                      Submit
                    </Button>
                  </Form>
                </Segment>
              </Media>
              <Media greaterThan="mobile">
                <Segment
                  style={{
                    width: '50%',
                    padding: 24,
                    boxShadow: '0 1px 10px 2px rgb(123 136 168 / 15%)',
                    border: 'none',
                    margin: 'auto',
                  }}
                >
                  <Form onSubmit={sendEmail}>
                    <Form.Field
                      error={error && !name}
                      onChange={(e) => setName(e.target.value)}
                    >
                      <label>Name</label>
                      <input placeholder="Name" />
                    </Form.Field>
                    <Form.Field
                      error={error && !email}
                      onChange={(e) => setEmail(e.target.value)}
                    >
                      <label>Email</label>
                      <input placeholder="Email" />
                    </Form.Field>
                    <Form.TextArea
                      error={error && !message}
                      onChange={(e) => setMessage(e.target.value)}
                      label="Message"
                      placeholder="Type your message here"
                    />
                    <Button
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                      primary
                    >
                      Submit
                    </Button>
                  </Form>
                </Segment>
              </Media> */}
            </Section>
          </div>
        </Layout>
        <PageFooter />
      </MediaContextProvider>
    </>
  );
};

export default RootIndex;
