import React from 'react';
import { Link } from 'gatsby';
import { Card, Button, Image } from 'semantic-ui-react';
import { Media } from '../media';

const ButtonFooter = ({
  isLarge = false,
  websiteUrl,
  designUrl,
}: {
  isLarge?: boolean;
  websiteUrl: string;
  designUrl: string;
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    }}
  >
    {websiteUrl && (
      <Link to={websiteUrl} target="_blank" rel="noreferrer">
        <Button
          primary
          size={isLarge ? 'large' : 'medium'}
          style={{ width: '100%', margin: '0px 0px 12px 0px' }}
        >
          View website
        </Button>
      </Link>
    )}
    {designUrl && (
      <Link to={designUrl} target="_blank" rel="noreferrer">
        <Button
          basic
          primary
          size={isLarge ? 'large' : 'medium'}
          style={{ width: '100%' }}
        >
          View designs
        </Button>
      </Link>
    )}
  </div>
);

export const ProjectCard = ({
  header,
  subheader,
  body,
  image,
  websiteUrl,
  designUrl,
}: {
  header: string;
  subheader: string;
  body: string;
  image: any;
  websiteUrl: string;
  designUrl: string;
}) => {
  return (
    <>
      <Media greaterThan="mobile">
        <Card
          style={{
            width: '90%',
            padding: 16,
            height: 700,
            boxShadow: '0 1px 10px 2px rgb(123 136 168 / 15%)',
            border: 'none',
            margin: 0,
          }}
        >
          <Image src={image} />
          <Card.Content style={{ borderTop: 'none' }}>
            <Card.Header>{header}</Card.Header>
            <Card.Meta style={{ marginBottom: 24 }}>{subheader}</Card.Meta>
            <Card.Description>{body}</Card.Description>
          </Card.Content>
          <ButtonFooter websiteUrl={websiteUrl} designUrl={designUrl} />
        </Card>
      </Media>
      <Media at="mobile">
        <Card
          style={{
            width: '100%',
            padding: 16,
            boxShadow: '0 1px 10px 2px rgb(123 136 168 / 15%)',
            border: 'none',
            margin: 0,
          }}
        >
          <Image src={image} />
          <Card.Content style={{ borderTop: 'none' }}>
            <Card.Header>{header}</Card.Header>
            <Card.Meta style={{ marginBottom: 24 }}>{subheader}</Card.Meta>
            <Card.Description>{body}</Card.Description>
          </Card.Content>
          <ButtonFooter websiteUrl={websiteUrl} designUrl={designUrl} />
        </Card>
      </Media>
    </>
  );
};
