import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Menu, Sidebar, Icon, Button } from 'semantic-ui-react';
import styles from './navigation.module.css';
import { Media } from '../media';
import Logo from '../images/heart-logo.svg';
import LogoDark from '../images/heart-logo-dark.svg';

export default () => {
  const [isVisible, setVisible] = useState(false);
  return (
    <>
      <Media greaterThan="mobile">
        <nav role="navigation">
          <ul className={styles.navigation}>
            <div>
              <li className={styles.navigationItem}>
                <Link to="/">
                  <img src={Logo} style={{ width: 55 }} />
                </Link>
              </li>
            </div>
            <div style={{ display: 'flex' }}>
              <div>
                <li className={styles.navigationItem}>
                  <a href="#about">About</a>
                </li>
              </div>
              <div>
                <li className={styles.navigationItem}>
                  <a href="#previousEmployers">Employers</a>
                </li>
              </div>
              <div>
                <li className={styles.navigationItem}>
                  <a href="#portfolio">Portfolio</a>
                </li>
              </div>
              <div>
                <li className={styles.navigationItem}>
                  <a href="#technologies">Technologies</a>
                </li>
              </div>
              <div>
                <li className={styles.navigationItem}>
                  <a href="#contact">Contact</a>
                </li>
              </div>
              {/* <div>
                <li className={styles.navigationItem}>
                  <a
                    href="https://blog.codewithnicol.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </li>
              </div> */}
              <div>
                <Button
                  circular
                  icon="twitter"
                  color="twitter"
                  as={(properties: any) => (
                    <a
                      {...properties}
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/NicolNvojacek"
                    />
                  )}
                />
                <Button
                  circular
                  icon="linkedin"
                  color="linkedin"
                  as={(properties: any) => (
                    <a
                      {...properties}
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/in/nicol-vojacek-444271106/"
                    />
                  )}
                />
                <Button
                  circular
                  icon="github"
                  as={(properties: any) => (
                    <a
                      {...properties}
                      target="_blank"
                      rel="noreferrer"
                      href="https://github.com/ni3ol"
                    />
                  )}
                />
              </div>
            </div>
          </ul>
        </nav>
      </Media>
      <Media at="mobile">
        <Sidebar
          as={Menu}
          animation="overlay"
          direction="right"
          vertical
          visible={isVisible}
        >
          <Menu.Item>
            <div style={{ textAlign: 'end' }}>
              <Icon
                onClick={() => setVisible(!isVisible)}
                size="large"
                name="close"
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
                fontWeight: 'bold',
                borderBottom: '1px solid',
              }}
            >
              <Link to="/">
                <img src={LogoDark} style={{ width: 55 }} />
              </Link>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
              }}
            >
              <a href="#about">About</a>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
              }}
            >
              <a href="#previousEmployers">Previous employers</a>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
              }}
            >
              <a href="#portfolio">Portfolio</a>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
              }}
            >
              <a href="#technologies">Technologies</a>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
              }}
            >
              <a href="#contact">Contact</a>
            </div>
            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
              }}
            >
              <a
                href="https://blog.codewithnicol.com/"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </div> */}
            <div style={{ textAlign: 'center' }}>
              <Button
                circular
                icon="twitter"
                color="twitter"
                as={(properties: any) => (
                  <a
                    {...properties}
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/NicolNvojacek"
                  />
                )}
              />
              <Button
                circular
                icon="linkedin"
                color="linkedin"
                as={(properties: any) => (
                  <a
                    {...properties}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/in/nicol-vojacek-444271106/"
                  />
                )}
              />
              <Button
                circular
                icon="github"
                as={(properties: any) => (
                  <a
                    {...properties}
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/ni3ol"
                  />
                )}
              />
            </div>
          </Menu.Item>
        </Sidebar>
        <Sidebar.Pushable>
          <Sidebar.Pusher
            dimmed={isVisible}
            onClick={isVisible ? () => setVisible(!isVisible) : undefined}
          >
            <Menu
              secondary
              style={{
                padding: 5,
              }}
            >
              <Menu.Item>
                <Link to="/">
                  <img src={Logo} style={{ width: 55 }} />
                </Link>
              </Menu.Item>
              <Menu.Menu position="right">
                <Menu.Item
                  onClick={() => {
                    setVisible(!isVisible);
                  }}
                >
                  <Icon name="bars" inverted />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    </>
  );
};
