import React from 'react';
import { Link } from 'gatsby';
import { Button, Header, Container } from 'semantic-ui-react';
import Navigation from './navigation';
import { Media } from '../media';
import Nicol from '../images/nicole.svg';
import Layout from './layout';
import Hero from '../images/hero.svg';
import MobileHero from '../images/mobile-hero.svg';
import TabletHero from '../images/tablet-hero.svg';

export default () => {
  return (
    <>
      <Media greaterThan="tablet">
        <div
          style={{
            position: 'relative',
            paddingBottom: 1000,
          }}
        >
          <img
            alt="hero"
            src={Hero}
            style={{
              position: 'absolute',
              width: '100%',
              marginTop: -50,
            }}
          />

          <div
            style={{
              position: 'absolute',
              width: '100%',
            }}
          >
            <Container>
              <Navigation />
              <div style={{ marginTop: 150, display: 'flex' }}>
                <div>
                  <Header inverted as="h1" style={{ fontSize: 50 }}>
                    Hi, I'm Nicol
                  </Header>
                  <Header inverted as="h2" style={{ fontWeight: 400 }}>
                    I'm a fullstack software developer turned startup founder
                    who enjoys building products and leveraging technology in
                    exciting ways
                  </Header>
                  <a href="#contact">
                    <Button
                      size="large"
                      style={{
                        backgroundColor: 'white',
                        color: '#974efe',
                      }}
                    >
                      Contact me
                    </Button>
                  </a>
                </div>
                <div>
                  <img alt="girl" src={Nicol} />
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Media>
      <Media at="tablet">
        <div
          style={{
            position: 'relative',
            paddingBottom: 750,
          }}
        >
          <img
            alt="hero"
            src={TabletHero}
            style={{
              position: 'absolute',
              width: '100%',
            }}
          />

          <div
            style={{
              position: 'absolute',
              width: '100%',
            }}
          >
            <Container>
              <Navigation />
              <div style={{ marginTop: 100, display: 'flex' }}>
                <div>
                  <Header inverted as="h1" style={{ fontSize: 50 }}>
                    Hi, I'm Nicol
                  </Header>
                  <Header inverted as="h2" style={{ fontWeight: 400 }}>
                    I'm a fullstack developer who likes to design and build
                    websites, turn ideas into products, and leverage technology
                    in exciting ways
                  </Header>
                  <a href="#contact">
                    <Button
                      size="large"
                      style={{
                        backgroundColor: 'white',
                        color: '#974efe',
                      }}
                    >
                      Contact me
                    </Button>
                  </a>
                </div>
                <div>
                  <img alt="girl" src={Nicol} />
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Media>
      <Media at="mobile">
        <div
          style={{
            position: 'relative',
            paddingBottom: 650,
          }}
        >
          <img
            alt="hero"
            src={MobileHero}
            style={{
              position: 'absolute',
              width: '100%',
            }}
          />

          <div
            style={{
              position: 'absolute',
              width: '100%',
            }}
          >
            <Container>
              <Navigation />
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <Header inverted as="h1" style={{ fontSize: 35 }}>
                  Hi, I'm Nicol
                </Header>
                <Header
                  inverted
                  as="h2"
                  style={{
                    fontWeight: 400,
                    fontSize: 22,
                  }}
                >
                  I'm a fullstack developer who likes to design and build
                  websites, turn ideas into products, and leverage technology in
                  exciting ways
                </Header>
                <a href="#contact">
                  <Button
                    size="large"
                    style={{
                      backgroundColor: 'white',
                      color: '#974efe',
                    }}
                  >
                    Contact me
                  </Button>
                </a>
                <img
                  alt="girl"
                  src={Nicol}
                  style={{ width: 250, margin: 'auto', display: 'flex' }}
                />
              </div>
            </Container>
          </div>
        </div>
      </Media>
    </>
  );
};
