import React from 'react';
import { chunk } from 'lodash';
import { Grid, Header, Segment } from 'semantic-ui-react';
import Node from '../images/svgs/nodejs-icon.svg';
import JavaScript from '../images/svgs/javascript.svg';
import TypeScript from '../images/svgs/typescript-icon.svg';
import Python from '../images/svgs/python.svg';
import Express from '../images/svgs/express.svg';
import Css from '../images/svgs/css-3.svg';
import Docker from '../images/svgs/docker-icon.svg';
import Figma from '../images/svgs/figma.svg';
import Gatsby from '../images/svgs/gatsby.svg';
import Graphql from '../images/svgs/graphql.svg';
import Heroku from '../images/svgs/heroku-icon.svg';
import Netlify from '../images/svgs/netlify.svg';
import Postgres from '../images/svgs/postgresql.svg';
import Rails from '../images/svgs/rails.svg';
import Ruby from '../images/svgs/ruby.svg';
import Redux from '../images/svgs/redux.svg';
import Reactjs from '../images/svgs/react.svg';
import SemanticUi from '../images/svgs/semantic-ui.svg';
import Elasticsearch from '../images/svgs/elasticsearch.svg';
import Mongo from '../images/svgs/mongo.svg';
import { Media } from '../media';

const items = [
  {
    title: 'React',
    image: Reactjs,
  },
  {
    title: 'JavaScript',
    image: JavaScript,
  },
  {
    title: 'TypeScript',
    image: TypeScript,
  },
  {
    title: 'Express',
    image: Express,
  },
  {
    title: 'Node',
    image: Node,
  },
  {
    title: 'CSS3',
    image: Css,
  },
  {
    title: 'Semantic UI',
    image: SemanticUi,
  },
  {
    title: 'Redux',
    image: Redux,
  },
  {
    title: 'Figma',
    image: Figma,
  },
  {
    title: 'Ruby',
    image: Ruby,
  },
  {
    title: 'Rails',
    image: Rails,
  },
  {
    title: 'Python',
    image: Python,
  },
  {
    title: 'GraphQL',
    image: Graphql,
  },
  {
    title: 'Elasticsearch',
    image: Elasticsearch,
  },
  {
    title: 'Mongo',
    image: Mongo,
  },
  {
    title: 'Postgres',
    image: Postgres,
  },
  {
    title: 'Docker',
    image: Docker,
  },
  {
    title: 'Netlify',
    image: Netlify,
  },
  {
    title: 'Heroku',
    image: Heroku,
  },
  {
    title: 'Gatsby',
    image: Gatsby,
  },
];

export const TechnologyStack = () => {
  return (
    <>
      <Media at="mobile">
        <div style={{ width: '100%' }}>
          <Grid columns={2} padded>
            {chunk(items, 2).map((chunks) => (
              <Grid.Row key={JSON.stringify(chunks.map(({ title }) => title))}>
                {chunks.map(({ title, image }) => {
                  return (
                    <Grid.Column key={title}>
                      <Segment
                        textAlign="center"
                        style={{
                          boxShadow: '0 1px 10px 2px rgb(123 136 168 / 15%)',
                          border: 'none',
                        }}
                      >
                        <Header textAlign="center">{title}</Header>
                        <img src={image} width="55" height="55" />
                      </Segment>
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            ))}
          </Grid>
        </div>
      </Media>
      <Media greaterThan="mobile">
        <div style={{ width: '100%' }}>
          <Grid columns={5} padded>
            {chunk(items, 5).map((chunks) => (
              <Grid.Row key={JSON.stringify(chunks.map(({ title }) => title))}>
                {chunks.map(({ title, image }) => {
                  return (
                    <Grid.Column key={title}>
                      <Segment
                        textAlign="center"
                        style={{
                          boxShadow: '0 1px 10px 2px rgb(123 136 168 / 15%)',
                          border: 'none',
                        }}
                      >
                        <Header textAlign="center">{title}</Header>
                        <img src={image} width="55" height="55" />
                      </Segment>
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            ))}
          </Grid>
        </div>
      </Media>
    </>
  );
};
