import React from 'react';
import { Header } from 'semantic-ui-react';
import { Media } from '../media';

export default ({
  header,
  subheader,
  children,
  id,
}: {
  header: string;
  subheader?: string;
  children: any;
  id: string;
}) => {
  return (
    <>
      <Media at="mobile">
        <div id={id} style={{ marginBottom: 100 }}>
          <Header
            style={{
              color: '#383838',
              textAlign: 'center',
              fontSize: 45,
            }}
            as="h2"
          >
            {header}
          </Header>
          <Header
            style={{
              color: '#383838',
              textAlign: 'center',
              fontSize: 22,
              fontWeight: 400,
              marginBottom: 50,
            }}
          >
            {subheader}
          </Header>
          {children}
        </div>
      </Media>
      <Media greaterThan="mobile">
        <div id={id} style={{ marginBottom: 150 }}>
          <Header
            style={{
              color: '#383838',
              textAlign: 'center',
              fontSize: 45,
            }}
            as="h2"
          >
            {header}
          </Header>
          <Header
            style={{
              color: '#383838',
              textAlign: 'center',
              fontSize: 22,
              fontWeight: 400,
              marginBottom: 50,
            }}
          >
            {subheader}
          </Header>
          {children}
        </div>
      </Media>
    </>
  );
};
