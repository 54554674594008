/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
// import { Media } from '../utils/app-media';
import Nicol from '../images/heart-logo.svg';

export const PageFooter = () => {
  return (
    <div
      style={{ paddingTop: 80, paddingBottom: 80, backgroundColor: '#8c47ff' }}
    >
      <Container>
        {/* <Media greaterThan="mobile"> */}
        <Grid columns={1}>
          <Grid.Column>
            <img src={Nicol} style={{ width: 150, margin: 'auto' }} />
          </Grid.Column>
        </Grid>
        <Grid columns={1} style={{ marginTop: 40 }}>
          <Grid.Column textAlign="center">
            <p style={{ color: 'white' }}>© 2021 Code with Nicol</p>
          </Grid.Column>
        </Grid>
        {/* </Media> */}
        {/* <Media at="mobile">
          <Grid columns={1} style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Grid.Row>
              <Grid.Column>{Company}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{Help}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{Resources}</Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginTop: 30 }}>
              <Grid.Column>
                <p style={{ color: 'white' }}>© 2021 TechUp School</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media> */}
      </Container>
    </div>
  );
};
